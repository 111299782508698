import axios from "axios";
import dayjs from "dayjs";
import store from "@/store";
import { encrypt } from "@/utils/common.js";

export const baseURL = "https://oaapi2.kdainfo.com/";
// export const baseURL = "http://localhost:25444/";

const requestWrapper = (path, jsonObject, method = "post") => {
    var time = dayjs().format("YYMMDD")
    if (jsonObject.userToken) {
        jsonObject.userToken.token = encrypt(time)
    }
    return axios
        .request({
            url: baseURL + "api" + path,
            method: method,
            headers: { Authorization: "Bearer " + store.state.user.jwt },
            data: jsonObject,
        })
        .then((res) => {
            return res.data;
        });
};

export const Login = (jsonObject) => {
    return requestWrapper("/login", jsonObject);
};

export const SaveAttach = (jsonObject) => {
    return requestWrapper("/File/SaveAttach", jsonObject);
};
export const GetDataOptions = (jsonObject) => {
    return requestWrapper("/BaseInfo/GetDataOptions", jsonObject);
};
export const GetDataChildOptions = (jsonObject) => {
    return requestWrapper("/BaseInfo/GetDataChildOptions", jsonObject);
};
export const GetDatadictionary = (jsonObject) => {
    return requestWrapper("/BaseInfo/GetDatadictionary", jsonObject);
};
export const GetUser = (jsonObject) => {
    return requestWrapper("/User/GetUsers", jsonObject);
};
export const GetSale = (jsonObject) => {
    return requestWrapper("/Sale/GetSale", jsonObject);
};
export const SaveSale = (jsonObject) => {
    return requestWrapper("/Sale/SaveSale", jsonObject);
};
export const AuditSale = (jsonObject) => {
    return requestWrapper("/Sale/AuditSale", jsonObject);
};
export const CancelSale = (jsonObject) => {
    return requestWrapper("/Sale/CancelSale", jsonObject);
};
export const GetInquiry = (jsonObject) => {
    return requestWrapper("/Sale/GetInquiry", jsonObject);
};
export const SaveInquiry = (jsonObject) => {
    return requestWrapper("/Sale/SaveInquiry", jsonObject);
};
export const TransferSale = (jsonObject) => {
    return requestWrapper("/Sale/TransferSale", jsonObject);
};
export const GetPurchase = (jsonObject) => {
    return requestWrapper("/Purchase/GetPurchase", jsonObject);
};
export const AuditPurchase = (jsonObject) => {
    return requestWrapper("/Purchase/AuditPurchase", jsonObject);
};
export const CancelPurchase = (jsonObject) => {
    return requestWrapper("/Purchase/CancelPurchase", jsonObject);
};
export const GetChance = (jsonObject) => {
    return requestWrapper("/Project/GetChance", jsonObject);
};
export const SaveChance = (jsonObject) => {
    return requestWrapper("/Project/SaveChance", jsonObject);
};
export const GetProject = (jsonObject) => {
    return requestWrapper("/Project/GetProject", jsonObject);
};
export const SaveProject = (jsonObject) => {
    return requestWrapper("/Project/SaveProject", jsonObject);
};
export const AuditProject = (jsonObject) => {
    return requestWrapper("/Project/AuditProject", jsonObject);
};
export const CancelProject = (jsonObject) => {
    return requestWrapper("/Project/CancelProject", jsonObject);
};
export const GetProjectInvoice = (jsonObject) => {
    return requestWrapper("/Project/GetProjectInvoice", jsonObject);
};
export const SaveProjectInvoice = (jsonObject) => {
    return requestWrapper("/Project/SaveProjectInvoice", jsonObject);
};
export const AuditProjectInvoice = (jsonObject) => {
    return requestWrapper("/Project/AuditProjectInvoice", jsonObject);
};
export const CancelProjectInvoice = (jsonObject) => {
    return requestWrapper("/Project/CancelProjectInvoice", jsonObject);
};
export const GetProjectOptions = (jsonObject) => {
    return requestWrapper("/Project/GetProjectOptions", jsonObject);
};
export const GetCustomerOptions = (jsonObject) => {
    return requestWrapper("/BaseInfo/GetCustomerOptions", jsonObject);
};
export const GetTaskulous = (jsonObject) => {
    return requestWrapper("/Taskulous/GetList", jsonObject);
};
export const GetCost = (jsonObject) => {
    return requestWrapper("/Cost/GetList", jsonObject);
};
export const SaveCost = (jsonObject) => {
    return requestWrapper("/Cost/Save", jsonObject);
};
export const AuditCost = (jsonObject) => {
    return requestWrapper("/Cost/Audit", jsonObject);
};
export const CancelCost = (jsonObject) => {
    return requestWrapper("/Cost/Cancel", jsonObject);
};
// 备货需求
export const GetStockDemand = (jsonObject) => {
    return requestWrapper("/StockDemand/GetList", jsonObject);
};
export const SaveStockDemand = (jsonObject) => {
    return requestWrapper("/StockDemand/Save", jsonObject);
};
export const AuditStockDemand = (jsonObject) => {
    return requestWrapper("/StockDemand/Audit", jsonObject);
};
export const CancelStockDemand = (jsonObject) => {
    return requestWrapper("/StockDemand/Cancel", jsonObject);
};
// 出差申请
export const GetTravel = (jsonObject) => {
    return requestWrapper("/Travel/GetList", jsonObject);
};
export const SaveTravel = (jsonObject) => {
    return requestWrapper("/Travel/Save", jsonObject);
};
export const AuditTravel = (jsonObject) => {
    return requestWrapper("/Travel/Audit", jsonObject);
};
export const CancelTravel = (jsonObject) => {
    return requestWrapper("/Travel/Cancel", jsonObject);
};
// 业务交流
export const GetEntertain = (jsonObject) => {
    return requestWrapper("/Entertain/GetList", jsonObject);
};
export const AuditEntertain = (jsonObject) => {
    return requestWrapper("/Entertain/Audit", jsonObject);
};
export const SaveEntertain = (jsonObject) => {
    return requestWrapper("/Entertain/Save", jsonObject);
};
export const CancelEntertain = (jsonObject) => {
    return requestWrapper("/Entertain/Cancel", jsonObject);
};
// 客户拜访
export const GetVisitPlan = (jsonObject) => {
    return requestWrapper("/VisitPlan/GetList", jsonObject);
};
export const SaveVisitPlan = (jsonObject) => {
    return requestWrapper("/VisitPlan/Save", jsonObject);
};
export const AuditVisitPlan = (jsonObject) => {
    return requestWrapper("/VisitPlan/Audit", jsonObject);
};
export const CancelVisitPlan = (jsonObject) => {
    return requestWrapper("/VisitPlan/Cancel", jsonObject);
};
export const ArriveVisitPlanItem = (jsonObject) => {
    return requestWrapper("/VisitPlan/ArriveItem", jsonObject);
};
export const FinishVisitPlanItem = (jsonObject) => {
    return requestWrapper("/VisitPlan/FinishItem", jsonObject);
};
export const AddVisitPlanItem = (jsonObject) => {
    return requestWrapper("/VisitPlan/AddItem", jsonObject);
};
export const AddVisitPlanReview = (jsonObject) => {
    return requestWrapper("/VisitPlan/AddReview", jsonObject);
};
export const DeleteVisitPlanReview = (jsonObject) => {
    return requestWrapper("/VisitPlan/DeleteReview", jsonObject);
};
export const GetVisitPlanItem = (jsonObject) => {
    return requestWrapper("/VisitPlan/GetItem", jsonObject);
};
export const GetVisitPlanItems = (jsonObject) => {
    return requestWrapper("/VisitPlan/GetItems", jsonObject);
};
export const GetVisitPlanReview = (jsonObject) => {
    return requestWrapper("/VisitPlan/GetReview", jsonObject);
};
export const GetVisitPlanReviews = (jsonObject) => {
    return requestWrapper("/VisitPlan/GetReviews", jsonObject);
};

export const GetNews = (jsonObject) => {
    return requestWrapper("/News/GetList", jsonObject);
};
export const GetTask = (jsonObject) => {
    return requestWrapper("/Task/GetList", jsonObject);
};
// 销售发票
export const GetInvoice = (jsonObject) => {
    return requestWrapper("/Sale/GetInvoice", jsonObject);
};
export const AuditInvoice = (jsonObject) => {
    return requestWrapper("/Sale/AuditInvoice", jsonObject);
};
// 打卡
export const GetTimecardLog = (jsonObject) => {
    return requestWrapper("/Timecard/GetTimecardLog", jsonObject);
};
export const AuditTimecardLog = (jsonObject) => {
    return requestWrapper("/Timecard/AuditTimecardLog", jsonObject);
};
// 请假
export const GetVacation = (jsonObject) => {
    return requestWrapper("/Timecard/GetVacation", jsonObject);
};
export const SaveVacation = (jsonObject) => {
    return requestWrapper("/Timecard/SaveVacation", jsonObject);
};
export const AuditVacation = (jsonObject) => {
    return requestWrapper("/Timecard/AuditVacation", jsonObject);
};
export const CancelVacation = (jsonObject) => {
    return requestWrapper("/Timecard/CancelVacation", jsonObject);
};
// 其他收付款
export const GetOtherMoney = (jsonObject) => {
    return requestWrapper("/OtherMoney/GetList", jsonObject);
};
export const AuditOtherMoney = (jsonObject) => {
    return requestWrapper("/OtherMoney/Audit", jsonObject);
};
export const CancelOtherMoney = (jsonObject) => {
    return requestWrapper("/OtherMoney/Cancel", jsonObject);
};
// 车辆（使用/费用）
export const GetCar = (jsonObject) => {
    return requestWrapper("/Car/GetCar", jsonObject);
};
export const GetCarUse = (jsonObject) => {
    return requestWrapper("/Car/GetCarUse", jsonObject);
};
export const SaveCarUse = (jsonObject) => {
    return requestWrapper("/Car/SaveCarUse", jsonObject);
};
export const AuditCarUse = (jsonObject) => {
    return requestWrapper("/Car/AuditCarUse", jsonObject);
};
export const CancelCarUse = (jsonObject) => {
    return requestWrapper("/Car/CancelCarUse", jsonObject);
};
export const OutCarUse = (jsonObject) => {
    return requestWrapper("/Car/OutCarUse", jsonObject);
};
export const ReturnCarUse = (jsonObject) => {
    return requestWrapper("/Car/ReturnCarUse", jsonObject);
};
export const GetCarCost = (jsonObject) => {
    return requestWrapper("/Car/GetCarCost", jsonObject);
};
export const SaveCarCost = (jsonObject) => {
    return requestWrapper("/Car/SaveCarCost", jsonObject);
};

export const GetReply = (jsonObject) => {
    return requestWrapper("/Daily/GetReply", jsonObject);
};
export const ReplyDaily = (jsonObject) => {
    return requestWrapper("/Daily/AddDailyReply", jsonObject);
};
export const DeleteDailyReply = (jsonObject) => {
    return requestWrapper("/Daily/DeleteDailyReply", jsonObject);
};

export const GetTeamUser = (jsonObject) => {
    return requestWrapper("/TeamGoal/GetTeamUser", jsonObject);
};
export const SaveTeamGoal = (jsonObject) => {
    return requestWrapper("/TeamGoal/SaveGoal", jsonObject);
};
export const GetTeamGoalDetail = (jsonObject) => {
    return requestWrapper("/TeamGoal/GetGoalDetail", jsonObject);
};
export const GetTeamRankBoard = (jsonObject) => {
    return requestWrapper("/TeamGoal/GetRankBoard", jsonObject);
};
export const GetTeamVisitRank = (jsonObject) => {
    return requestWrapper("/TeamGoal/GetVisitRank", jsonObject);
};
// 帐号申请
export const GetAccountApply = (jsonObject) => {
    return requestWrapper("/AccountApply/GetList", jsonObject);
};
export const AuditAccountApply = (jsonObject) => {
    return requestWrapper("/AccountApply/Audit", jsonObject);
};
export const SaveAccountApply = (jsonObject) => {
    return requestWrapper("/AccountApply/Save", jsonObject);
};
export const CancelAccountApply = (jsonObject) => {
    return requestWrapper("/AccountApply/Cancel", jsonObject);
};
export const OpenAccountApply = (jsonObject) => {
    return requestWrapper("/AccountApply/Open", jsonObject);
};

// 试点申请
export const GetProjectTrial = (jsonObject) => {
    return requestWrapper("/Project/GetProjectTrial", jsonObject);
};
export const SaveProjectTrial = (jsonObject) => {
    return requestWrapper("/Project/SaveProjectTrial", jsonObject);
};
export const AuditProjectTrial = (jsonObject) => {
    return requestWrapper("/Project/AuditProjectTrial", jsonObject);
};
export const CancelProjectTrial = (jsonObject) => {
    return requestWrapper("/Project/CancelProjectTrial", jsonObject);
};
export const OpenProjectTrial = (jsonObject) => {
    return requestWrapper("/Project/OpenProjectTrial", jsonObject);
};

//催款通知
export const GetRemindList = (jsonObject) => {
    return requestWrapper("/Remind/GetList", jsonObject);
};

// 项目总结
export const GetProjectSummary = (jsonObject) => {
    return requestWrapper("/Project/GetProjectSummary", jsonObject);
};
export const SaveProjectSummary = (jsonObject) => {
    return requestWrapper("/Project/SaveProjectSummary", jsonObject);
};
export const AuditProjectSummary = (jsonObject) => {
    return requestWrapper("/Project/AuditProjectSummary", jsonObject);
};
export const CancelProjectSummary = (jsonObject) => {
    return requestWrapper("/Project/CancelProjectSummary", jsonObject);
};

// 音频信息
export const GetAudio = (jsonObject) => {
    return requestWrapper("/Audio/GetList", jsonObject);
};